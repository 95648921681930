<template>
  <div class="swim-race">
    <div class="container py-5">
      <ul class="gamelist">
        <li v-for="(item) in sortedRace" :key="item.serial">
          <div class="race-card">
            <div class="race-card-body">
              <p>{{ item.serial }}. {{ item.GameName }}</p>

              <p class="text-danger" v-if="item.GameStatus === 1">進行中</p>
              <p class="text-secondary" v-if="item.GameStatus === 2">已結束</p>

              <label class="font-weight-bold">活動時間</label>
              <p>開始: {{ __momentStr(item.StartTime) }}</p>
              <p v-if="item.GameStatus === 2">結束: {{ __momentStr(item.EndTime) }}</p>

              <div class="row justify-content-between">
                <div class="col-auto">
                  <button class="btn btn-primary" @click="editClicked(item)">編輯</button>
                </div>
                <div class="col-auto" v-if="item.GameStatus === 2">
                  <button class="btn btn-success" @click="viewResultClicked(item)">檢視成果</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <b-modal id="modal-1" title="編輯" v-model="showEditModal" @ok="okClicked">
      <div>
        <select class="form-control mb-3" v-model="editObj.GameStatus">
          <option :value="1">進行中</option>
          <option :value="2">已結束</option>
        </select>

        <div class="mb-3">
          <label>開始時間</label>
          <input class="form-control" type="datetime" v-model="editObj.StartTime" />
        </div>

        <div class="mb-3">
          <label>結束時間</label>
          <input class="form-control" type="datetime" v-model="editObj.EndTime" />
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-2" title="編輯" v-model="showResultModal">
      <div>
        <p>共 {{ recordsSummary.length }} 位參賽者資料</p>
        <p>以每 {{timePeriod}} 秒傳送一次數據計算</p>

        <div v-for="(item) in recordsSummary" :key="item.id" class="card mb-3">
          <div class="card-body">
            <label>{{ item.id }}</label>
            <p class="m-0">時長 {{ Math.floor((item.lastTimestamp - item.firstTimestamp) / 1000 / 60) }} 分鐘</p>
            <p class="m-0">理論點數: {{ Math.floor((item.lastTimestamp - item.firstTimestamp) / 1000 / 15) }}</p>
            <p class="m-0">實際有效資料點數(不具GPS的點): {{ item.gpsCount }} ({{ item.dataCount - item.gpsCount }})</p>
            <p class="m-0">資料流失率: {{ ((1 - (item.gpsCount / Math.floor((item.lastTimestamp - item.firstTimestamp) / 1000 / 15))) * 100).toFixed(2) }} %</p>

          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import axios from 'axios';
import moment from 'moment';

// @ is an alias to /src

export default {
  name: 'SwimRace',
  data() {
    return {
      showEditModal: false,
      showResultModal: false,
      races: [],
      gameRecords: [],
      timePeriod: 15,

      editObj: {
        serial: -1,
        GameStatus: 1,
        StartTime: '',
        EndTime: '',
      }
    };
  },
  computed: {
    sortedRace() {
      const r = [... this.races];
      return r.sort((a, b) => {
        return a.serial - b.serial;
      });
    },
    recordsSummary() {
      const res = this.gameRecords.reduce((ary, item) => {
        for (let i=0;i<ary.length;i++) {
          if (ary[i].id === item.deviceId) {
            ary[i].dataCount += 1;
            if (item.lat !== undefined) {
              ary[i].gpsCount += 1;
            }
            ary[i].firstTimestamp = Math.min(ary[i].firstTimestamp, item.time);
            ary[i].lastTimestamp = Math.max(ary[i].lastTimestamp, item.time);
            return ary;
          }
        }
        ary.push({
          id: item.deviceId,
          dataCount: 1,
          gpsCount: (item.lat === undefined?0:1),
          firstTimestamp: item.time,
          lastTimestamp: item.time,
        });
        return ary;
      }, []);
      return res;
    },
  },
  components: {
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const BUSYNAME = 'READRACELISTBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const gameList = await this.$store.dispatch('api/readGameListPromise');
        this.races.splice(0, this.races.length);
        for (const game of gameList) {
          this.races.push(game);
        }
        // console.log(gameList);
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    __momentStr(t) {
      return moment(t).format('YYYY-MM-DD HH:mm:ss');
    },
    editClicked(g) {
      this.editObj.serial = g.serial;
      this.editObj.GameStatus = g.GameStatus;
      this.editObj.StartTime = moment(g.StartTime).format('YYYY-MM-DD HH:mm:ss');
      if (this.editObj.GameStatus === 2) {
        this.editObj.EndTime = moment(g.EndTime).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.editObj.EndTime = '';
      }
      this.showEditModal = true;
    },
    async okClicked(event) {
      event.preventDefault();

      const BUSYNAME = 'SAVERACEBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const obj = { ... this.editObj};
        obj.StartTime = moment(obj.StartTime).toDate().getTime();
        if (obj.GameStatus === 1) {
          obj.EndTime = null;
        } else if (obj.GameStatus === 2) {
          obj.EndTime = moment(obj.EndTime).toDate().getTime();
        }
        // console.log(obj);
        const newRace = await this.$store.dispatch('api/updateGamePromise', obj);
        for (let i=0;i<this.races.length;i++) {
          if (this.races[i].serial === newRace.serial) {
            this.races.splice(i, 1, newRace);
            break;
          }
        }
        this.showEditModal = false;
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async viewResultClicked(game) {
      const BUSYNAME = 'READRECORDBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const allRecord = await this.$store.dispatch('api/readRecordByGamePromise', game.serial);
        this.gameRecords.splice(0, this.gameRecords.length);
        for (let i=0;i<allRecord.length;i++) {
          this.gameRecords.push(allRecord[i]);
        }
        this.showResultModal = true;
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    }
  },
};
</script>

<style lang="scss" scoped>

  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-button {
    background: transparent;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid slategrey;
  }

  *::-webkit-scrollbar-track {
    box-shadow: transparent;
  }

  .swim-race {
    height: 100%;
  }

  ul.gamelist {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
      flex: 0 0 33.33%;
      margin: 0;
      padding: .5rem 1rem;
      list-style: none;
    }
  }

  .race-card {
    border-radius: .5rem;
    background-color: #fff;
    padding: 1rem 1.5rem;
    .race-card-body {

    }
  }

</style>
